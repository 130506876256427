.contractors {
  @extend .projects;
}

.contractors-content {
  @extend .projects-content;

  &__subheader-title-actions {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 1px 0px #efefef;
    padding-bottom: 0.8rem;
    &__project-title {
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      h2 {
        color: $gray-dark;
      }
      &__edit {
        margin-left: 1.2rem;
        position: relative;
        top: -5px;
        svg {
          fill: $gray-dark;
        }
        &:hover {
          cursor: pointer;
        }
      }
      @include tablet {
        h2 {
          font-size: 1.75rem;
        }
      }
    }

    &__actions {
      button {
        margin-left: 0.2rem;
        &:focus {
          box-shadow: none !important;
        }
      }
      .active {
        background-color: #dae0e5;
        border-color: #d3d9df;
      }
    }

    @include phone {
      flex-direction: column;
    }
  }

  &__project-title {
    box-shadow: 0px 1px 0px #efefef;
    padding-bottom: 0.8rem;
    display: flex;
    justify-content: space-between;
  }
}

@mixin table-grid {
  display: grid;
  grid-template-columns: repeat(7, auto) 4% 1%;
  grid-auto-rows: minmax(60px, 75px);
  padding: 0.4rem 2rem;
  row-gap: 1rem;
  @media screen and(min-width: 1240px) and (max-width: 1480px) {
    grid-template-columns: repeat(6, auto) 4% 1%;
  }
  @media screen and (min-width: 1100px) and (max-width: 1240px) {
    grid-template-columns: repeat(5, auto) 4% 1%;
  }
  @media screen and (min-width: 920px) and (max-width: 1100px) {
    grid-template-columns: repeat(4, auto) 4% 1%;
  }

  @media screen and (min-width: 768px) and (max-width: 920px) {
    grid-template-columns: repeat(3, auto) 4% 1%;
  }

  @include tablet {
    grid-template-columns: repeat(2, auto) 4% 1%;
  }
}

.contractors-table {
  @include table-grid();

  .data-row {
    background-color: $table-background-color;
  }

  .header {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin-top: 2rem;
  }

  .bordered {
    border-radius: 0.3rem;
    border-left: 7px solid #dce6f2;
    transition: border-left 0.3s linear;
  }

  &__header {
    &__id {
      padding-left: 3.6rem;
    }
  }

  &__folder-icon {
    transition: color 0.3s linear;
    margin-left: 0.5rem;
    justify-self: center;
    margin-right: 15px;
    font-size: 28px;
  }

  &__id {
    &:hover {
      cursor: pointer;
    }
  }

  &__trade {
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }

  &__name {
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 920px) {
      display: none !important;
    }
  }

  &__sum {
    flex-direction: row-reverse;
    padding-right: 2rem !important;
  }

  &__contact {
    @media screen and (max-width: 1240px) {
      display: none !important;
    }
  }

  &__email {
    @media screen and (max-width: 1480px) {
      display: none !important;
    }
  }

  &__phone {
    @media screen and (max-width: 1100px) {
      display: none !important;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }
}

.contractors-display-message {
  margin-top: 2rem;
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  text-align: center;

  @include tablet {
    margin-top: 1rem;
    font-size: $font-size-base;
  }
}
