.projects {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 120px 1fr;
  @include phone {
    display: flex;
    flex-direction: column;
  }
  @include tablet {
    column-gap: 2rem;
  }
}

.projects-content {
  grid-column-start: 2;
  margin-top: 1.1rem;
  padding: 2rem;
  outline: none;
  @include tablet {
    padding: 1.3rem;
  }
}

.projects-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 0px #efefef;
  padding-bottom: 1.1rem;
  h4 {
    color: $gray-dark;
  }
}

.projects-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  margin-top: 2.5rem;
  grid-gap: 2rem;

  @include tablet {
    grid-template-columns: repeat(1, 1fr);
  }

  @include widescreen {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: 768px) and (max-width: 1120px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.projects-display-message {
  @extend .contractors-display-message;
}
