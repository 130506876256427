@mixin list-grid {
  display: grid;
  grid-template-columns: 52px repeat(5, auto) 4% 1%;
  grid-auto-rows: minmax(60px, 75px);
  padding: 0.4rem 2rem;
  row-gap: 1rem;

  @media screen and (max-width: 1260px) {
    grid-template-columns: 52px repeat(4, auto) 4% 1%;
  }
  @media screen and (max-width: 1100px) {
    grid-template-columns: 52px repeat(3, auto) 4% 1%;
  }
  @media screen and (max-width: 960px) {
    grid-template-columns: 52px repeat(2, auto) 4% 1%;
  }
  @media screen and (max-width: 820px) {
    grid-template-columns: 52px auto 4% 1%;
  }
}

.domains {
  @extend .admin;
}

.domains-content {
  @extend .admin-content;

  &__list {
    @include list-grid();

    .header {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      margin-top: 3rem;
    }

    .data-row {
      display: flex;
      align-items: center;
      background-color: $table-background-color;
    }

    &__id {
      span {
        overflow-wrap: break-word;
      }
    }

    &__tenantName {
      display: none !important;
    }

    &__logo {
      @media screen and (max-width: 820px) {
        display: none !important;
      }
    }

    &__screen-image {
      img {
        margin-left: 1rem;
      }
      @media screen and (max-width: 1260px) {
        display: none !important;
      }
    }
    &__dark-color {
      @media screen and (max-width: 1100px) {
        display: none !important;
      }
    }
    &__light-color {
      @media screen and (max-width: 960px) {
        display: none !important;
      }
    }

    &__dark-color,
    &__light-color {
      div {
        border: 0.2px solid black;
        height: 32px;
        width: 64px;
        margin-left: 1rem;
      }
    }

    img {
      max-width: 64px;
      max-height: 64px;
      border-radius: 0.3rem;
    }

    & > div {
      padding-right: 1rem;
    }
  }
}
