@mixin list-grid {
  grid-template-columns: 52px repeat(3, auto) 4% 1%;
  @media screen and(max-width: 1080px) {
    grid-template-columns: 52px repeat(2, auto) 4% 1%;
  }
  @media screen and(max-width: 960px) {
    grid-template-columns: 52px auto 4% 1%;
  }
}

.users {
  @extend .admin;
}

.users-content {
  @extend .admin-content;

  &__list {
    @extend .domains-content__list;
    @include list-grid();

    &__email {
      word-break: break-word;
    }

    &__phone {
      @media screen and(max-width: 1080px) {
        display: none !important;
      }
    }
    &__name {
      @media screen and(max-width: 960px) {
        display: none !important;
      }
    }
  }
}
