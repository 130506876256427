.subheader {
  display: grid;
  box-shadow: 0px 1px 0px #efefef;
  padding-bottom: 1.7rem;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-box-align: center;

    h2 {
      color: $gray-dark;
    }
    button {
      font-size: $font-size-base;
      @include tablet {
        font-size: $font-size-medium;
      }
    }
  }
}

.subheader-navigation {
  display: flex;
  p {
    padding-right: 0.3rem;
    font-size: $font-size-base;
    @include phone {
      font-size: $font-size-medium;
    }
  }
}

.subheader-action-row {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-box-align: center;
    margin-top: 2rem;

    h4 {
      color: $gray-dark;
    }
  }
  &__buttons {
    display: flex;
    &__additional {
      margin-right: 0.5rem;
    }
  }
  button {
    font-size: $font-size-base;
    @include tablet {
      font-size: $font-size-medium;
    }
  }
}
