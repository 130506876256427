.calculations {
  @extend .projects;
}

@mixin table-grid {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-auto-rows: minmax(30px, 75px);
  padding: 0.4rem 2rem;
  row-gap: 1rem;
  @media screen and(min-width: 1280px) and (max-width: 1440px) {
    grid-template-columns: repeat(5, auto);
  }
  @media screen and(min-width: 1140px) and (max-width: 1280px) {
    grid-template-columns: repeat(4, auto);
  }
  @media screen and(min-width: 1000px) and (max-width: 1140px) {
    grid-template-columns: repeat(3, auto);
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, auto);
  }
}

.calculations-content {
  @extend .projects-content;
}

.calculations-table {
  @extend .contractors-table;
  @include table-grid();
  padding-bottom: 2.5rem;

  .table-shadow-line {
    margin-top: 40px;
    box-shadow: 0px -1px 0px #efefef;
  }

  &__header {
    &__task {
      flex-direction: row;
      padding-left: 2.8rem;
    }
  }

  &__task {
    flex-direction: row;
    border-radius: 0.3rem;
    text-align: start;
    i {
      color: #94abc8;
      margin: 0 10px 0 10px;
    }
  }

  &__completed-total {
    @media screen and (max-width: 1440px) {
      display: none !important;
    }
  }

  &__last-month-amount {
    @media screen and (max-width: 1140px) {
      display: none !important;
    }
  }

  &__current-month-amount {
    @media screen and (max-width: 1000px) {
      display: none !important;
    }
  }

  &__total-contract-amount {
    @media screen and (max-width: 1280px) {
      display: none !important;
    }
  }

  & > .right-alignment {
    flex-direction: row-reverse;
    text-align: right;
  }

  &__completed-this-period {
    text-align: right;
    input {
      border: none;
      outline: none;
      background-color: #fff;
      border-bottom: 1px solid #dce6f2;
      width: 90px;
      color: inherit;
      padding: 0.4rem;
      text-overflow: ellipsis;
      text-align: right;
    }
    @include phone {
      input {
        width: 75px;
      }
    }
  }

  &__footer {
    &__title {
      margin-bottom: 30px;
      grid-column: 4;
    }
    &__value {
      margin-bottom: 30px;
      padding: 0.7rem 1rem 0.7rem 0.5rem;
      background-color: #f6f8fc;
      border-radius: 7px;
      grid-column: 5;
    }
    @media screen and (max-width: 1440px) {
      &__title {
        grid-column: 3;
      }
      &__value {
        grid-column: 4;
      }
    }
    @media screen and (max-width: 1140px) {
      &__title {
        grid-column: 2;
      }
      &__value {
        grid-column: 3;
      }
    }
    @media screen and (max-width: 1000px) {
      &__title {
        grid-column: 1;
      }
      &__value {
        flex-direction: row;
        justify-content: center;
        width: 50%;
        grid-column: 2;
        min-width: 100px;
      }
    }
  }
  .grid-display {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  .flexbox-display {
    display: none;
    @media screen and (max-width: 1000px) {
      display: flex;
    }
  }
}

.table-shadow-line.six {
  @media screen and (max-width: 1440px) {
    display: none;
  }
}

.table-shadow-line.five {
  @media screen and (max-width: 1280px) {
    display: none;
  }
}

.table-shadow-line.four {
  @media screen and (max-width: 1140px) {
    display: none;
  }
}

.table-shadow-line.three {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
