.admin {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 120px 1fr;
  @include tablet {
    display: flex;
    flex-direction: column;
  }
}

.admin-sidebar {
  grid-column-start: 1;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  padding: 1rem;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 120px;

  &__super-admin {
    &--visible {
      display: flex;
      color: #d3110d;
      margin-bottom: 1rem;
      font-weight: $font-weight-bold;
      order: 0;
    }
    &--hidden {
      display: none;
    }
  }

  &__logo {
    order: 1;
  }

  &__items {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    order: 2;
  }

  .sidebar__footer__user {
    margin-top: auto;
    order: 3;
    @include tablet {
      margin-top: initial;
      height: 32px;
      width: 32px;
      border-radius: 0.2rem;
      background-color: #fff !important;
      i {
        color: #94abc8 !important;
      }
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    padding: 0.2rem 0;
    i {
      height: 24px;
      width: 24px;
      margin-right: 0.6rem;
    }
    &:hover {
      cursor: pointer;
    }
  }

  @include tablet {
    flex-direction: row;
    position: unset;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    &__logo {
      order: 2;
    }

    &__items {
      order: 1;
      margin-top: 0;
      i {
        color: #94abc8 !important;
      }
    }

    .logo-component {
      height: 32px;
      align-self: initial;
    }
    &__list-item {
      span {
        display: none;
      }
    }

    .popup-menu {
      font-size: $font-size-medium;
      right: 5px;
      top: 60px;
      bottom: unset;
      left: unset;
      border: 1px solid transparent;
      width: 120px;
      min-height: 60px;
    }
  }
}

.admin-content {
  grid-column-start: 2;
  padding: 2rem;

  h1 {
    text-align: center;
  }
}

.admin-subheader {
  &__navigation {
    @extend .subheader-navigation;
  }
  &__title {
    box-shadow: 0px 1px 0px #efefef;
    padding-bottom: 0.8rem;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    button {
      font-size: $font-size-base;
      @include tablet {
        font-size: $font-size-medium;
      }
    }
  }
}
