.table-more {
  text-align: center;
  justify-content: center;
  border-radius: 0 0.3rem 0.3rem 0;
  &__content {
    &:after {
      content: '\2807';
      font-size: $font-size-xlarge;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
