.sidebar {
  grid-column-start: 1;
  position: fixed;
  top: 0;
  z-index: 1000;
  bottom: 0;
  left: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  width: 120px;

  &__hamburger-menu {
    display: none;
    @include phone {
      display: inline;
      visibility: hidden;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__settings {
      color: #94abc8;
      margin-bottom: 1rem;
      &:hover {
        cursor: pointer;
      }
    }

    &__user {
      width: 40px;
      height: 40px;
      border-radius: 0.4rem;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      align-items: center;
      -webkit-box-align: center;
      &:hover {
        cursor: pointer;
      }
    }

    @include phone {
      flex-direction: row-reverse;

      &__settings {
        margin-bottom: 0;
        margin-top: 0.4rem;
      }

      &__user {
        height: 32px;
        width: 32px;
        border-radius: 0.2rem;
        background-color: #fff !important;
        i {
          color: #94abc8 !important;
        }
      }
    }
  }

  @include phone {
    flex-direction: row;
    position: unset;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    .logo-component {
      height: 32px;
    }
  }
}
