.sidepane-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  align-items: center;

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    @include phone {
      font-size: $font-size-base;
    }
  }

  &__close {
    color: #aaaaaa;
    float: right;
    font-size: 20px;
    font-weight: bold;
    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }

  @include phone {
    padding-bottom: 1rem;
  }
}
