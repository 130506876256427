.skeleton-pulse {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    -90deg,
    $table-background-color,
    #dce6f2,
    $table-background-color
  );
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
}

.skeleton-line {
  @extend .skeleton-pulse;
  width: 5.5em;
  border-radius: 5px;

  &::before {
    content: '\00a0';
  }
}

.skeleton-cards {
  @extend .projects-cards;
}

.skeleton-card {
  @extend .skeleton-pulse;
  @extend .project-card;
}

.skeleton-table {
  margin-top: 2rem;
}

.skeleton-table-header {
  display: grid;
  grid-template-columns: 40% 40%;
  padding: 1rem;
  margin-bottom: 1rem;

  .skeleton-line {
    height: 20px;
    width: 120px;
  }

  @include tablet {
    .skeleton-line {
      width: 75px;
    }
  }
}

.skeleton-table-row {
  @extend .skeleton-table-header;
  border: 1px solid #efefef;
  border-radius: 0.3rem;
}
