@mixin phone {
  @media screen and (max-width: 640px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media screen and(min-width: 992px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: 1640px) {
    @content;
  }
}
