.popup-modal .modal-body {
  height: 300px;
  box-shadow: 0.1rem 0.1rem 0.5rem #abc4c9;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.popup-modal .modal-content {
  background-color: transparent;
  border: none;
}

.popup-modal-tick {
  border: 1px solid white;
  border-radius: 3rem;
  padding: 1rem;
}

.popup-modal-content {
  color: white;
  &__message {
    font-size: $font-size-xxlarge;
  }
}

.success-modal-body {
  background: linear-gradient(45deg, #17ce82, #5ad8aa);
}

.error-modal-body {
  background: linear-gradient(45deg, #f29461, #e63415);
}
