body {
  font-family: $font-family !important;
  font-size: $font-size-base !important;
  color: $gray-dark !important;
  letter-spacing: 0.05rem;

  @include phone {
    margin: 0;
  }
  @include tablet {
    font-size: $font-size-medium !important;
  }
}
