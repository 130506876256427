.sidepane-footer {
  display: flex;
  padding-top: 0.1rem;

  &__btn-cancel {
    margin-right: 0.5rem !important;
  }
}

.sidepane-footer button {
  margin: 0.5rem 0 0.5rem 0;
  width: 45vw;
}
