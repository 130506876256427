.table-context-menu {
  position: relative;
  top: 72px;
  right: 135px;
  background: #ffffff;
  width: 138px;
  height: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.25);
  list-style: none;
  padding: 0;

  &__item {
    padding: 10px 20px;
    &:hover {
      background-color: #f6f8fc;
      cursor: pointer;
    }
  }
}
