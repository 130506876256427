.select-wrapper {
  position: relative;
  margin: 2rem 0 0 2rem;
  width: 135px;
  height: 32px;
  border: 2px solid #e9eff7;
  border-radius: 7px;
  padding: 11px 28px 10px 12px;
  letter-spacing: 0.04em;
  -moz-appearance: none;
  -webkit-appearance: none;
  line-height: 1;
  outline: none;

  &:after {
    content: 'v';
    position: absolute;
    top: 5px;
    left: 112px;
    color: #a8b1bd;
    pointer-events: none;
  }

  &--span {
    position: relative;
    top: -3px;
  }

  &__list {
    position: relative;
    right: 13px;
    flex-direction: column;
    margin-top: 0.4rem;
    border: 2px solid #e5e5e5;
    width: 135px;
    padding: 0.4rem 0 1rem 0;
    background-color: #fff;
    list-style: none;
    span {
      padding: 8px 10px;
      color: #a8b1bd;
      text-align: center;
      &:hover {
        cursor: pointer;
        background-color: #f6f8fc;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}
