.sidepane {
  height: 100vh;
  width: 30vw;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  background-color: white;
  transition: transform 0.2s linear;
  -webkit-transition: -webkit-transform 0.2s linear;
  @include phone {
    width: 100vw;
  }
  box-shadow: -11px 0 16px 4px rgba(188, 195, 207, 0.13);
}

.sidepane-content {
  background-color: white;
  color: $gray-dark;
  height: 100vh;
  width: 30vw;
  padding: 35px 25px 15px 25px;
  position: absolute;
  right: 0;
  overflow-y: auto;
  @include phone {
    width: 100vw;
    padding: 15px;
  }

  input[type='color'] {
    border: none;
    height: 64px;
  }
}

.sidepane .form-control {
  margin-bottom: 1.2rem;
  @include phone {
    margin-bottom: 0.6rem;
  }
}
