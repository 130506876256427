.popup-menu {
  background: #ffffff;
  min-height: 75px;
  width: 140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.25);
  list-style: none;
  padding: 0;
  position: absolute;
  left: 90px;
  bottom: 60px;

  &__item {
    padding: 5px 10px;
    &:hover {
      background-color: #f6f8fc;
      cursor: pointer;
    }
    &__icon {
      width: 24px;
      height: 24px;
      padding: 0.3rem;
      margin-right: 0.6rem;
    }
  }

  @include phone {
    font-size: $font-size-medium;
    right: 5px;
    top: 60px;
    bottom: unset;
    left: unset;
    border: 1px solid transparent;
    width: 120px;
    min-height: 60px;
  }
}
