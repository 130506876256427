.table-border-icon {
  display: flex;
  align-items: center;
  background-color: $table-background-color;
  border-radius: 0.3rem 0 0 0.3rem;
  border-left: 7px solid #dce6f2;
  transition: border-left 0.3s linear;

  i {
    color: #94abc8;
    padding: 0rem 0.6rem;
    transition: color 0.3s linear;
  }
}
