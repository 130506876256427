.custom-remember-me {
  position: relative;
  display: inline-flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  label {
    margin-left: 28px;
  }

  &__checkbox {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: #dce6f2 solid 0.1rem;
    border-radius: 0.25rem;

    &:after {
      position: absolute;
      display: none;
    }
  }

  input:checked ~ .custom-remember-me__checkmark {
    background-color: #00b050;
    transition: background-color 0.25s linear;
    border: none;
    &:after {
      content: '';
      display: block;
      left: 7px;
      top: 3px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 1.5px 1.5px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
