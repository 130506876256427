.progress-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  padding: 0.6rem;

  .progress {
    height: 60px;
  }
}
