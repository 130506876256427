.calculations-subheader {
  &__navigation {
    display: flex;
    p {
      padding-right: 0.3rem;
      font-size: $font-size-base;
      @include phone {
        font-size: $font-size-medium;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;

    &__title {
      display: flex;
      h2 {
        color: $gray-dark;
        margin-right: 10px;
      }

      @include phone {
        flex-direction: column;
      }
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    @include tablet {
      padding-bottom: 1.5rem;
    }
  }

  &__footer {
    padding-bottom: 1.7rem;
    box-shadow: 0px 1px 0px #efefef;

    &__informations {
      display: flex;
      align-items: center;
      padding: 1.5rem 1.5rem;
      background-color: $table-background-color;
      border-radius: 0.7rem;
      i {
        color: #94abc8;
        margin-right: 2rem;
      }
      &__info-blocks {
        display: flex;
        flex-direction: column;
        width: 80%;

        @media screen and (max-width: 1080px) {
          text-align: center;
        }
      }

      @include tablet {
        i {
          margin-right: 1rem;
        }
      }
    }

    @include phone {
      &__info-block {
        span {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.calculations-info-block {
  padding: 0.2rem 0;
  &__column-display {
    display: none;
    flex-direction: column;
  }

  @media screen and (max-width: 1080px) {
    &__row-display {
      display: none;
    }
    &__column-display {
      display: flex;
    }
  }
}
