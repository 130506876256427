.periods {
  @extend .projects;
}

.periods-content {
  @extend .projects-content;
}

@mixin table-grid {
  display: grid;
  grid-template-columns: repeat(2, auto) 4% 1%;
  grid-auto-rows: minmax(60px, 75px);
  padding: 0.4rem 2rem;
  row-gap: 1rem;
}

.periods-table {
  max-width: 70%;
  @extend .contractors-table;
  @include table-grid();

  &__header {
    &__date {
      padding-left: 2.8rem;
    }
  }

  &__date {
    i {
      color: #94abc8;
      margin: 0 10px 0 5px;
    }
    &--flex {
      display: flex;
      span {
        margin-right: 0.3rem;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__amount {
    flex-direction: row-reverse;
    padding-right: 1.5rem !important;

    &:hover {
      cursor: pointer;
    }
  }

  @include tablet {
    max-width: 100%;
  }
}

.periods-display-message {
  @extend .contractors-display-message;
  display: flex;
  flex-direction: column;
  button {
    margin-top: 1.5rem;
  }
}
