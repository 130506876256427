.project-card {
  padding: 1.8rem;
  border: none;
  border-radius: 0.5rem;
  min-height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
  transition: color 0.3s linear, background-color 0.3s linear;

  &__display-name {
    font-size: $font-size-large;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  &__project-id {
    font-size: $font-size-base;
  }

  &__btn {
    font-size: $font-size-base !important;
    border: none !important;
    border-radius: 0.5rem !important;
    height: 40px;
    width: 110px;
    position: absolute;
    bottom: 1rem;
  }

  &__blocks {
    svg {
      position: absolute;
      transition: fill 0.3s linear;
      height: auto;
      width: 95px;
    }

    &__light-icon {
      right: 8px;
      bottom: -28px;
      z-index: 2;
    }
    &__dark-icon {
      right: -7px;
      bottom: -17px;
    }
  }

  &:hover {
    color: white;
    cursor: pointer;
    .project-card__blocks__light-icon {
      fill: #ffc000;
    }
    .project-card__blocks__dark-icon {
      fill: #b90f0c;
    }
  }

  @include phone {
    &__display-name {
      font-size: $font-size-base;
    }
    &__btn,
    &__id {
      font-size: $font-size-medium !important;
    }
  }
}
