.tasks {
  @extend .projects;
}

.tasks-content {
  @extend .projects-content;
}

@mixin table-grid {
  display: grid;
  grid-template-columns: repeat(3, auto) 5%;
  grid-auto-rows: minmax(55px, 75px);
  padding: 0.4rem 2rem;
  row-gap: 1rem;

  @media screen and (max-width: 980px) {
    grid-template-columns: repeat(2, auto) 5%;
  }
}

.tasks-table {
  @extend .contractors-table;
  @include table-grid();

  .data-row {
    &--visible {
      opacity: 1;
      visibility: visible;
      transition: visibility 0s ease-in-out, opacity 0.2s ease-in-out;
      -webkit-transition: visibility 0s ease-in-out, opacity 0.2s ease-in-out;
    }
    &--hidden {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s ease-in-out, opacity 0.2s ease-in-out;
      -webkit-transition: visibility 0s ease-in-out, opacity 0.2s ease-in-out;
    }
  }

  .task-group {
    background-color: #dce6f2;
    color: black;
  }
  .task-sub-group {
    background-color: $table-background-color;
    color: black;
    padding-left: 1rem;
  }
  .task {
    padding-left: 2rem;
    background-color: #f8f9fa;
    font-size: $font-size-medium;
  }
  .right-alignment {
    flex-direction: row-reverse;
    text-align: right;
  }

  &__header {
    &__id {
      padding-left: 3rem;
    }
  }

  &__id {
    border-radius: 0.3rem 0 0 0.3rem;
    i {
      transition: color 0.3s linear;
      padding-left: 8px;
      padding-right: 15px;
      color: #94abc8;
    }
    &.task-sub-group {
      margin-left: 8px;
    }
    &.task {
      margin-left: 16px;
    }
  }

  &__name {
    @media screen and (max-width: 980px) {
      display: none !important;
    }
  }

  &__arrow {
    padding: 0 !important;
    z-index: 10;
    justify-content: center;
    border-radius: 0rem 0.3rem 0.3rem 0rem;
    &:hover {
      cursor: pointer;
    }
  }
  .active {
    transform: rotate(180deg);
    border-radius: 0.3rem 0 0 0.3rem;
  }
}
