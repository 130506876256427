$font-family: 'Lato', sans-serif;

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

$font-size-xxlarge: 1.625rem; // 26px
$font-size-xlarge: 1.25rem; // 20px
$font-size-large: 1.125rem; // 18px
$font-size-base: 1rem; // 16px
$font-size-medium: 0.875rem; // 14px
$font-size-small: 0.75rem; // 12px
$font-size-xsmall: 0.625rem; // 10px

$gray-dark: #5b6b83;
$table-background-color: #f6f8fc;
