.login {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: auto;
  width: 100%;

  button {
    height: 40px;
    margin-bottom: 1rem;
    @include phone {
      height: 36px;
    }
  }
  p {
    margin-top: 3.5rem;
  }
}

.login-form {
  padding: 4rem;
  max-width: calc(430px + 2rem);
  margin-right: auto;
  background-color: white;
  height: 100vh;

  @include phone {
    max-width: 100%;
    padding: 1.5rem;
    text-align: center;
  }

  &__password-input {
    position: absolute;
    top: 31%;
    right: 8%;
    &:hover {
      cursor: pointer;
    }
  }
}

.login-welcome-message {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: $font-size-xxlarge;
  font-weight: bold;

  @include phone {
    font-size: $font-size-xlarge;
  }
}

.login-spinners {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.logo {
  &__login {
    height: 60px;
  }

  @include phone {
    width: 100%;
  }
}

.error-message {
  color: red;
}
