@mixin list-grid {
  display: grid;
  grid-template-columns: 52px auto;
  grid-auto-rows: minmax(60px, 75px);
  padding: 0.4rem 2rem;
  row-gap: 1rem;
}

@mixin domains-list-grid {
  display: grid;
  grid-template-columns: 52px repeat(6, auto) 4% 1%;
  grid-auto-rows: minmax(60px, 75px);
  padding: 0.4rem 2rem;
  row-gap: 1rem;

  @media screen and (max-width: 1260px) {
    grid-template-columns: 52px repeat(5, auto) 4% 1%;
  }
  @media screen and (max-width: 1160px) {
    grid-template-columns: 52px repeat(4, auto) 4% 1%;
  }
  @media screen and (max-width: 1040px) {
    grid-template-columns: 52px repeat(3, auto) 4% 1%;
  }
  @media screen and (max-width: 940px) {
    grid-template-columns: 52px repeat(2, auto) 4% 1%;
  }
  @media screen and (max-width: 840px) {
    grid-template-columns: 52px auto 4% 1%;
  }
}

.super-admin {
  @extend .admin;
}

.super-admin-content {
  @extend .admin-content;

  &__list {
    @extend .domains-content__list;
    @include list-grid();
    max-width: 70%;
    &__name {
      border-radius: 0 0.3rem 0.3rem 0;
    }
  }
}

.super-admin-domains-content {
  &__list {
    @extend .domains-content__list;
    @include domains-list-grid();

    &__logo {
      @media screen and (max-width: 840px) {
        display: none !important;
      }
    }

    &__tenantName {
      @media screen and (max-width: 940px) {
        display: none !important;
      }
    }

    &__screen-image {
      img {
        margin-left: 1rem;
      }
      @media screen and (max-width: 1260px) {
        display: none !important;
      }
    }

    &__dark-color {
      @media screen and (max-width: 1160px) {
        display: none !important;
      }
    }

    &__light-color {
      @media screen and (max-width: 1040px) {
        display: none !important;
      }
    }

    &__dark-color,
    &__light-color {
      div {
        border: 0.2px solid black;
        height: 32px;
        width: 64px;
        margin-left: 1rem;
      }
    }
  }
}
